<template>
  <div class="sonContent">
  <el-dialog
      title="详情"
      :visible.sync="detilDialog"
      width="45%"
      :close-on-click-modal="false"
      :show-close="false"
    >
  <div style="max-height: 600px;overflow: auto">
    <p class="detil-code">
      工程信息
    </p>
    <div style="padding: 10px">
      <div style="display: flex;line-height: 50px">
        <div style="flex: 1;">工程名称：{{project.projectName}}</div>
        <div style="flex: 1;">工程类型：{{project.projectType}}</div>
        <div style="flex: 1;">现场管理员：{{project.siteManager}}</div>
      </div>
      <div style="display: flex;line-height: 50px">
        <div style="flex: 1;">工程编号：{{project.projectNumber}}</div>
        <div style="flex: 1;">工程地点：{{project.projectSite}}</div>
        <div style="flex: 1;">第三方施工队：{{project.constructionTeam}}</div>
      </div>
    </div>
    <p class="detil-code">
      提交信息
    </p>
    <div style="padding: 10px">
      <div style="display: flex;line-height: 50px">
        <div style="flex: 1;">提交人：{{project.createBy}}</div>
        <div style="flex: 1;">提交时间：{{project.createTime}}</div>
        <div style="flex: 1;"></div>
      </div>
    </div>
    <p class="detil-code">
      现场管理确认审核
    </p>
    <div style="padding: 10px">
      <div style="display: flex;line-height: 50px">
        <div style="flex: 1;">确认人：{{project.obtainBy}}</div>
        <div style="flex: 1;">确认时间：{{project.obtainTime}}</div>
        <div style="flex: 1;">审核人：{{project.checkTypeBy}}</div>
        <div style="flex: 1;">审核时间：{{project.checkTypeTime}}</div>
      </div>
    </div>
    <p class="detil-code">
      仓库管理确认审核
    </p>
    <div style="padding: 10px">
      <div style="display: flex;line-height: 50px">
        <div style="flex: 1;">确认人：{{project.pcObtainBy}}</div>
        <div style="flex: 1;">确认时间：{{project.pcObtainTime}}</div>
        <div style="flex: 1;">审核人：{{project.pcCheckBy}}</div>
        <div style="flex: 1;">审核时间：{{project.pcCheckTime}}</div>
      </div>
      <div style="display: flex;line-height: 50px">
        <div style="flex: 1;">需要退料：{{project.pcCheckMaterial==1?'是':'否'}}</div>
        <div style="flex: 1;">退料状态：
          <span v-if="project.pcCheckDescribeType==1">未退料</span>
          <span v-if="project.pcCheckDescribeType==2">退料中</span>
          <span v-if="project.pcCheckDescribeType==3">退料完成</span>
          <span v-if="project.pcCheckDescribeType==4">不需退料</span>
        </div>
        <div style="flex: 1;"></div>
        <div style="flex: 1;"></div>
      </div>
      <div style="flex: 1;">附加描述：{{project.pcCheckDescribe}}</div>
    </div>
    <p class="detil-code" v-if="hanleBtn==2">
      计统确认审核
    </p>
    <div style="padding: 10px" v-if="hanleBtn==2">
      <div style="display: flex;line-height: 50px">
        <div style="flex: 1;">确认人：{{project.pcStatisticsBy}}</div>
        <div style="flex: 1;">确认时间：{{project.pcStatisticsTime}}</div>
      </div>
      <div style="display: flex;line-height: 50px">
        <div style="flex: 1;">成本：{{project.pcAuditCost}}</div>
        <div style="flex: 1;">利润：{{project.pcAuditProfit}}</div>
        <div style="flex: 1;">项目总工程款：{{project.pcAuditFunds}}</div>
      </div>
      <div>
        <div style="flex: 1;">领导签字审批资料：
          <el-image
                  style="width: 100px; height: 100px"
                  :src="project.pcAuditUrl.split(',')[0]"
                  :preview-src-list="project.pcAuditUrl.split(',')">
          </el-image>
        </div>
      </div>
      <div style="display: flex;line-height: 50px">
        <div style="flex: 1;">审核人：{{project.pcAuditBy}}</div>
        <div style="flex: 1;">审核时间：{{project.pcAuditTime}}</div>
      </div>
    </div>
  </div>
    <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="close">关闭</el-button>
  </span>
  </el-dialog>
</div>
</template>

<script>
  import {auditParticulars} from "../../../RequestPort/cost/cost";
export default {
  name: "detilCost",
  props:{
    detilDialog:{
      type:[Boolean,String],
      default:false
    },
    hanleBtn:{
      type:[Number,String],
      default:null
    },
    project:{
      type:Object,
      default: null
    }
  },
  methods:{
    close(){
      this.$emit('closepop');
    }
  }
}
</script>

<style scoped>
.detil-code{
  padding-left: 10px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  color: #000000;
  position: relative;
}
.detil-code:before{
  content: '';
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007AFF;
  top: 0;
  left: 0;
}
</style>